

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.bet-group{
  padding: 5px 10px; border-bottom: 1px solid gray;border-top: 1px solid gray;
  margin-bottom: 10px;
}
.bet-group .betNum > span{
  display:inline-block; border-radius: 90px; background-color: #ffc107; 
  color:#233d63;margin:6px 3px; padding: 6px 10px;
}

.bet-group .againstNum > span{
  display:inline-block; border-radius: 90px; background-color: blue; 
  color:white;margin:6px 3px; padding: 6px 10px;
}

.bet-group .close {
  position: relative; top: 5px; right: 10px; color: red;
}

.collapse.in {        
  display: block;       
}

#loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    overflow: visible;
    background: #ffffff url(./assets/images/preloader.gif) no-repeat center center; 
  }


